.no-data-found {
  margin: 0;
  position: absolute;
  display: block;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  color: #9389f3;
  font-size: 20px;
}
