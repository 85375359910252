.sidebar {
  width: 210px;
  position: fixed;
  padding: 0px 22px;
  background-color: #ffff;
  height: 100%;
  left: 0;
  z-index: 3;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #aeabab;
}

.sidebar::-webkit-scrollbar-track {
  background: #e6e6e6;
}

.sidebar-closed {
  width: 60px !important;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
.sidebar-closed .menu-list-right {
  margin-right: 5px;
}

.sidebar-closed .logo img {
  padding-top: 10px;
  width: 70px !important;
}

.sidebar-closed-menu {
  display: none !important;
}

.logo {
  padding: 0;
  text-align: center;
}

.logo img {
  width: 168px;
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  border-radius: 7px;
}

.menu-bg {
  background: #2980ff;
}

.menu-list-left-icon {
  font-size: 22px;
  margin: 2px 8px 0 0;
}

.menu-list-left-text {
  font-size: 18px;
}

.menu-list-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-whilte span {
  color: #fff;
}

.menu-whilte span {
  color: rgba(4, 13, 23, 0.5);
}

.screen-name {
  font-size: 18px;
  color: rgba(4, 13, 23, 0.5);
  margin: 3px 0 0px;
  padding: 16px 15px;
}

.menu-list-left-text a.active-class {
  color: #2d5bff;
}

.active-class {
  color: #2d5bff;
}
