.settings-sidebar {
  width: 270px;
  height: 100vh;
}

.settings-sidebar h3 {
  margin: 0 0 18px;
  font-weight: 600;
  font-size: 24px;
}

.sidebar-main {
  border-right: 1px solid rgba(4, 4, 21, 0.1);
  padding-right: 40px;
}

.sidebar-menu-area p {
  font-weight: 600;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  padding: 0 0 0px;
  margin: 0 0 12px;
  border-bottom: 1px solid rgba(4, 4, 21, 0.1);
}

.sidebar-menu-area .sidebar-menu {
  margin: 0 0 20px;
}

.sidebar-menu > a {
  display: flex;
  align-items: center;
}

.sidebar-menu > button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

.sidebar-menu .active-class {
  color: #2d5bff;
}

.sidebar-menu .active-class .sidebar-icon {
  background: rgb(171, 212, 240);
}

.sidebar-icon {
  width: 36px;
  height: 36px;
  margin-right: 13px;
  background: rgba(6, 102, 235, 0.09);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-icon svg {
  font-size: 18px;
}

.sidbar-title {
  font-size: 16px;
}
