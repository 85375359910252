@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap");

body {
  background: linear-gradient(106.47deg, #fffbf4 1.39%, #f7faff 97.39%);
  color: rgba(4, 13, 23, 0.8);
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: rgba(4, 13, 23, 0.8);
}

input:focus-visible {
  outline-offset: 0;
  outline: 1.3px solid #0666eb;
}

textarea:focus-visible {
  outline-offset: 0;
  outline: 1.3px solid #0666eb;
}

select:focus-visible {
  outline-offset: 0;
  outline: 1.3px solid #0666eb;
}
.invoice-table {
  height: 67vh;
  overflow-y: scroll;
}

.invoice-table table {
  width: 100%;
}

.table-message {
  padding: 0px 20px;
  margin: 0 0 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table thead {
  height: 50px;
  background: #f7fdfa;
  font-weight: 500;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  padding: 10px 15px;
  position: sticky;
  top: 0;
}

table thead th {
  display: table-cell;
  text-align: left;
  padding: 12px 20px;
}

table tbody tr:hover {
  background: #fbfbfb;
}

table tbody td {
  display: table-cell;
  padding: 12px 20px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  border-bottom: 0.6px solid rgba(4, 4, 21, 0.15);
}

table tbody td.pending {
  color: #0066ff;
}

table tbody td.accepted {
  color: #2dbc77;
}

table tbody td.rejected {
  color: #ff0000;
}

table tbody td.deliverd {
  color: #ff9b00;
}

table tbody tr td span img {
  margin: 0 6px 0 0;
}

.prev-next-icon-left,
.prev-next-icon-right {
  font-size: 20px;
  margin: 10px;
}
.prev-next-icon-left {
  float: left;
}
.prev-next-icon-right {
  float: right;
}
.prev-next-icon-left:hover,
.prev-next-icon-right:hover {
  color: #FE9900;
  cursor: pointer;
}
