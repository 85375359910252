.edit-document-form {
  width: 716px;
}

.edit-document-form-area {
  background: #fff;
  padding: 40px 30px;
}

.edit-document-form-area h3 {
  font-weight: 500;
  font-size: 24px;
  color: #040d17;
  margin: 0 0 22px;
}
.fileImage-large {
  display: inline-block;
  margin-right: 5px;
}
.fileImage-large img,
.fileImage-large .shimmer {
  width: 250px;
  height: 250px;
  /* border-radius: 50%; */
}