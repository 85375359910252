.header {
  height: 82px;
  background-color: #fff;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0 32px;
  border-radius: 10px;
}

.menu-icon svg {
  font-size: 24px;
}

.search {
  width: 400px;
  position: relative;
}

.search input {
  border: none;
  width: 85%;
  background: #f6faff;
  border-radius: 8px;
  height: 50px;
  padding: 0 10px 0 50px;
  font-size: 18px;
}

.search span {
  position: absolute;
  left: 14px;
  top: 14px;
  font-size: 24px;
}

.top-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-icon span {
  font-size: 24px;
  margin: 0 30px 0 0px;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile p {
  margin: 0;
  font-size: 18px;
}

.profile span {
  font-size: 14px;
  color: rgba(4, 13, 23, 0.5);
}

.profile .partnerImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 12px 0px 0px;
}

.profile img {
  margin: 0 12px 0px 0px;
}
