.auth-form-button {
  width: 630px;
  height: 50px;
  color: #ffffff;
  text-align: center;
  box-shadow: 12px 12px 34px rgba(254, 102, 103, 0.18);
  border-radius: 26px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background: #7972cc;
}

/* button.form-button:hover {
  background: #fe0002;
  transition: 0.3s;
} */

.auth-form-button:disabled {
  background: rgb(184, 179, 236);
}
