.add-exchange-rate-form {
  width: 716px;
}

.add-exchange-rate-form-area {
  background: #fff;
  padding: 40px 30px;
}

.add-exchange-rate-form-area h3 {
  font-weight: 500;
  font-size: 24px;
  color: #040d17;
  margin: 0 0 22px;
}

.select-field select {
  width: 94%;
  height: 46px;
  border: 1px solid #dcdddf;
  border-radius: 7px;
  padding: 0 10px;
  font-size: 16px;
  color: rgba(4, 13, 23, 0.8);
}

.select-box {
  display: flex;
  height: auto;
  /* flex-direction: column; */
}
.select-box option {
  width: 94%;
  height: 46px;
  border: 1px solid #dcdddf;
  border-radius: 7px;
  padding: 0 10px;
  font-size: 16px;
  color: rgba(4, 13, 23, 0.8);
}

.select-box option {
  width: 94%;
  border: 1px solid #dcdddf;
  border-radius: 7px;
  padding: 15px 10px;
  font-size: 16px;
  color: rgba(4, 13, 23, 0.8);
}

.select-box {
  width: 100%;
  height: 47px;
  border: 1px px solid #dcdddf;
  border-radius: 7px;
  padding: 0 10px;
  font-size: 16px;
  color: rgba(4, 13, 23, 0.8);
}
