.form-page {
  display: flex;
}

.form-left {
  width: 522px;
  height: 100vh;
  background: url("../../../images/form-img.jpg") no-repeat;
  background-size: cover;
}

.form-logo {
  padding: 20px 0 0 35px;
}

.form-top {
  display: flex;
  justify-content: right;
  margin: 30px 50px 60px;
}

.form-right .form-top p {
  font-size: 18px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0 26px 0 0;
}

.form-right {
  flex-grow: 1;
  padding: 0 0 0 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-style {
  justify-content: flex-start;
}

.form-fields-area {
  width: 630px;
}

.form-button-area button {
  width: 100%;
}

.form-right form {
  margin-top: 37px;
}

.form-right .form-title h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  color: #040415;
  margin: 0 0 15px;
}

.form-right .form-title p {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}
