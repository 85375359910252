.table-top {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency-table {
  overflow: scroll;
  height: 100vh;
}

.currency-table table {
  width: 100%;
}

.fileImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.result-show span {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
}

.result-show select {
  border: 1.2px solid rgba(4, 4, 21, 0.14);
  border-radius: 7px;
  width: 86px;
  height: 40px;
  margin-left: 12px;
  padding: 8px 6px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(4, 4, 21, 0.5);
  /*   appearance: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right 1rem center;
          background-size: 1em; */
}

.link-btn-div a:hover {
  background: #0666eb !important;
}

.action-btn a.edit-icon {
  color: #2980ff;
  margin-right: 8px;
  font-size: 18px;
}

.action-btn button.delete-icon {
  background: no-repeat;
  border: none;
  font-size: 18px;
  color: #ff0000;
  padding: 0;
}

.checkbox {
  margin: 0 4px 0 15px;
}

.currency-table thead {
  padding: 10px 15px;
  height: 62px;
  background-color: #f7faff;
}

.currency-table thead th {
  font-weight: normal;
  text-align: left;
  padding: 0 8px;
}

.currency-table tbody th {
  font-weight: normal;
  text-align: left;
  display: table-cell;
  font-weight: normal;
  padding: 28px 8px;
}

.currency-table tbody tr th {
  border-bottom: 1px solid rgba(4, 13, 23, 0.1);
}

.currency-table-area {
  background: #fff;
  padding: 25px 25px;
  border-radius: 10px;
}
