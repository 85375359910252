.link-btn-div {
  display: flex;
}

.link-btn {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  border-radius: 6px;
  padding: 14px 35px;
  transition: 0.3s;
}
