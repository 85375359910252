.settings-profile-name h2 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(4, 4, 21, 0.8);
  margin: 0 0 6px;
}

.view-profile-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px;
}

.edit-photo-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #0666eb;
}

.view-profile-left {
  display: flex;
  align-items: center;
}

.settings-profile-name {
  margin: 0 0 0 22px;
}

.edit-profile-btn {
  background: none;
  border: 1px solid rgba(254, 102, 103, 0.16);
  border-radius: 28px;
  cursor: pointer;
  color: #fe6667;
  padding: 12px 28px;
  font-weight: 600;
  font-size: 16px;
}

.edit-profile-btn:hover {
  background: rgba(254, 102, 103, 0.06);
  transition: 0.3s;
}

.settings-btn-area {
  display: flex;
}
.image-on-profile img,
.image-on-profile .shimmer {
  width: 71px;
  height: 71px;
  border-radius: 50%;
}
.image-on-profile .hidden {
  display: none;
}
.image-on-profile .shown {
  display: block;
}
.partnerImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* margin: 0 12px 0px 0px; */
}
