.withdraw-form {
  width: 716px;
}

.withdraw-form-area {
  background: #fff;
  padding: 40px 30px;
}

.withdraw-form-area h3 {
  font-weight: 500;
  font-size: 24px;
  color: #040d17;
  margin: 0 0 22px;
}
