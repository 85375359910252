.main {
  flex-grow: 1;
  margin-left: 282px;
  padding-right: 30px;
  filter: drop-shadow(20px 20px 40px rgba(4, 13, 23, 0.04));
  margin-bottom: 85px;
  position: relative;
  height: 100vh;
  transition: all 0.1s ease-in-out;
}

.side-menu-open {
  margin-left: 130px !important;
}
