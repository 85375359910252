.form-button {
  grid-column: 1;
  margin-top: 18px;
}

.form-button .btn {
  width: 123px;
  padding: 15px 0px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  margin-right: 18px;
  font-size: 16px;
}

.btn-add {
  background: #9389f3;
  color: #fff;
}

.btn-add:hover {
  background: #756cd2;
  transition: 0.3s;
}

.btn-add:disabled {
  background: rgb(184, 179, 236);
}

.btn-close {
  background: rgba(4, 13, 23, 0.06);
  color: rgba(4, 13, 23, 0.5);
  margin-right: 0 !important;
}

.btn-close:hover {
  background: #ddd;
  transition: 0.3s;
}
